@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-dark {
  background-color: #131313;
}

.bg-light {
  background-color: #1b1b1b;
}

.border-default {
  border-color: #1b1b1b;
}

.border-active {
  border-color: #404249;
}

.border-dark {
  border-color: #232323;
}

.hide-number-arrows::-webkit-inner-spin-button,
.hide-number-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.arrow-wrapper {
  border-radius: 12px;
  height: 40px;
  width: 40px;
  position: relative;
  margin: -18px auto;
  background-color: rgb(27, 27, 27);
  border: 4px solid rgb(19, 19, 19);
  z-index: 2;
}

.swap-currency-button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
